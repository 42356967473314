import React from "react";
import { styled } from "styled-components";
import { ButtonArrow } from "../../icons";
import MainPlatformImage from "../Image/MainPlatform";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PlatformPurple = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleArrowClick = () => {
    navigate("/team");
  };

  return (
    <Container>
      <div>
        <div className="flex mt-11 md:mt-1 p-14 justify-center gap-[240px] lg:flex-col lg:gap-[40px] items-center">
          <div className="max-w-[600px] pb-[80px]  pt-[5px] flex flex-col gap-[40px] lg:items-center lg:pb-0 sm:pt-[30px]">
            <p className="flex flex-col font-bold max-w-[510px] max-h-[244px] text-[43px]  leading-[62.4px] sm:leading-[42.4px] sm:text-[30px] lg:justify-center md:text-center md:gap-4">
              <span>{t("main.communication")}</span>
              <span className="lg:text-center">UCWORKS</span>
            </p>

            <p className="max-w-[530px] text-[18px] font-semibold  sm:text-[12.4px]">
              <span className="lg:text-center">{t("main.subTitle")}</span>
              <span>{t("main.subTitlePlatform")}</span>
            </p>

            <button
              onClick={handleArrowClick}
              className="w-[267px] h-[48px] bg-[#9C52E6] text-[#FFF] rounded-[5px] text-left px-[20px] flex justify-between items-center"
            >
              <span className={`${i18n.language === "ja" && "text-[13px]"}`}>
                {t("main.freeBtn")}
              </span>
              <ButtonArrow />
            </button>
          </div>
          <div className="min-w-[500px] min-h-[500px] md:min-w-[300px]  md:min-h-[300px]">
            <MainPlatformImage
              index={2}
              className="min-w-[620px] min-h-[600px] md:min-w-[300px] md:min-h-[300px]"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-height: 700px;
  background: linear-gradient(
    180deg,
    #ffffff 7.31%,
    #fdfafd 45.78%,
    #f6f0ff 100%
  );
`;
export default PlatformPurple;
