import React from "react";

const MainPlatformImage = ({
  className,
  index,
}: ComponentProps & { index: number }) => {
  return (
    <div className={`${className ?? ""}`}>
      {index === 1 ? (
        <img
          src={`${process.env.PUBLIC_URL}/images/imagemain_1_bg.png`}
          className="animate-slideFade"
        />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}/images/imagemain_2_bg.png`}
          className="animate-slideFade"
        />
      )}
    </div>
  );
};

export default MainPlatformImage;
