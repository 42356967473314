import { useEffect, useState } from "react";
import AddHeader from "../../components/Add/AddHeader";
import { BottomArrow } from "../../icons";
import StorageSelectBox from "../../components/Add/StorageSelectBox";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/toast";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { AxiosError } from "axios";
import Skeleton from "react-loading-skeleton";
import useCalculateStorageCost from "../../hooks/use.calculate.storage.cost";
import useModifyStorage from "../../hooks/use.modify.storage.size";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { storageAddInfo, storageModifyPUT } from "../../utils/request";
import { ApiResponse } from "../../@types";
import PriceSkeleton from "../../components/Skeleton/PriceSkeleton";
import FullScreenLodable from "../../components/Lodable/FullScreenLoadble";

interface LocationState {
  tenantId?: string;
  nextPaymentDate: string;
}

const StorageAddPage = () => {
  const [selectedStorage, setSelectedStorage] = useState<number>(1);

  const { t } = useTranslation();
  const location = useLocation();
  const state: LocationState = location.state as LocationState;
  const navigate = useNavigate();

  const [totalPrice, VAT] = useCalculateStorageCost(
    selectedStorage,
    state.nextPaymentDate
  );

  const [monetaryUnit, setMonetaryUnit] = useState<string>("원");
  const [loading, setLoading] = useState<boolean>(false);
  const handleStorage = (storage: number) => {
    setSelectedStorage(storage);
  };

  const {
    data: priceInfo,
    isError,
    isFetching,
  } = useQuery<
    ApiResponse<{
      totalPrice: number;
      selectedStorage: number;
      hashData: string;
    }>
  >({
    queryKey: ["storageAddInfo", selectedStorage],
    queryFn: () =>
      storageAddInfo({ tenantId: state.tenantId!, selectedStorage }),
    staleTime: 0,
    gcTime: 0,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    enabled: !!state.tenantId,
  });

  const { mutate } = useMutation({
    mutationFn: (params: ModifyStorageParams) => {
      return storageModifyPUT(params);
    },
    onMutate: (parms: ModifyStorageParams) => {
      setLoading(true);
    },
    onSuccess: () => {
      setLoading(false);
      toastSuccess("스토리지 추가를 완료했습니다.");

      navigate(-1);
    },
    onError: (error) => {
      setLoading(false);
      console.log(error);
      toastSuccess("스토리지 추가 실패했습니다");
    },
  });
  const fetchStorageAddPUT = () => {
    mutate({
      tenantId: state.tenantId!,
      storageSizeToAdd: selectedStorage,
      totalPrice: priceInfo?.data.totalPrice!,
      hashData: priceInfo?.data.hashData!,
    });
  };
  useEffect(() => {}, [selectedStorage, state.tenantId]);

  return (
    <>
      <FullScreenLodable visible={loading} />
      <article>
        <AddHeader />
        <section className="w-full flex flex-col items-center">
          <div className="w-[504px] flex flex-col items-center gap-4">
            <div className="w-full">
              <h3 className="text-[36px] leading-[52.13px] font-noto-sans-kr text-center font-bold mb-9">
                {t("storage.title")}
              </h3>
              <StorageSelectBox
                storage={selectedStorage}
                onChange={handleStorage}
              />
            </div>

            <div className="w-full flex justify-between">
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                {t("add.productPrice")}
              </span>
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                {selectedStorage}TB X 100,000{monetaryUnit}
              </span>
            </div>

            <div className="w-full flex justify-between">
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                VAT(10%)
              </span>
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                {new Intl.NumberFormat("ko-KR").format(VAT)}
                {monetaryUnit}
              </span>
            </div>

            <div className="border-solid w-full border-[1px] border-[#555555]"></div>

            <div className="w-full flex justify-between mb-3">
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                {t("add.totalPrice")}
              </span>
              <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr flex">
                <PriceSkeleton
                  isFetching={isFetching}
                  price={priceInfo?.data.totalPrice!}
                />
                {monetaryUnit}
              </span>
            </div>

            <div className="flex flex-col w-full font-noto-sans-kr font-normal text-[14px] leading-[20.27px] text-[#555555] mb-5">
              {
                <span className="flex items-center">
                  {t("storage.storagePriceFront")}{" "}
                  <PriceSkeleton
                    isFetching={isFetching}
                    price={priceInfo?.data.totalPrice!}
                  />
                  {monetaryUnit}
                  {t("storage.storagePriceBack")}
                </span>
              }
            </div>

            <button
              className="w-[320px] h-[42px] bg-[#1F62B9] rounded-[4px] text-[#FFF] leading-[23.17px] text-[16px] font-bold"
              onClick={fetchStorageAddPUT}
            >
              {t("storage.title")}
            </button>
          </div>
        </section>
      </article>
    </>
  );
};

export default StorageAddPage;
