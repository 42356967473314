import React, { useEffect, useRef, useState, useTransition } from "react";
import styled from "styled-components";
import ModalLayout from "./Layout";
import { toastError, toastSuccess } from "../../utils/toast";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { data } from "autoprefixer";
import { error } from "console";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { on } from "events";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";

const WorkspaceDeleteModal = ({
  isOpen,
  onClose,
  setRequestLoading,
}: ModalProps & { setRequestLoading: (state: boolean) => void }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const workspaceDetail = useSelector(
    (state: RootState) => state.workspaceDetail.workspaceInfo
  );
  const [loading, setLoading] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const handleDelete = () => {
    setRequestLoading(true);
    if (workspaceDetail) {
      if (workspaceDetail.tenantId === inputRef.current?.value) {
        onClose();

        setLoading(true);
        axiosInstance
          .put(
            "/workspace/rollback",
            {
              tenantId: workspaceDetail.tenantId,
              restcode: workspaceDetail.restcode,
            },
            requestAuth({})
          )
          .then((data) => {
            setRequestLoading(false);
            toastSuccess(t("workspace.del.success"));

            navigate("/");
          })
          .catch((error) => {
            setRequestLoading(false);
            toastError(t("workspace.del.fail"));
          });
      } else {
        setVisible(true);
      }
    }
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} height="280px">
      <Wrapper>
        <Text>
          {t("workspace.del.checkTextQuestion")} <br />
          {t("workspace.del.checkText")} <br />
          <br />
          <span className="text-[#D20707]">
            {t("workspace.del.dangerText")}
          </span>
          <br></br>
          <DangerText>
            {workspaceDetail?.tenantId ? workspaceDetail?.tenantId : "NONE"}
          </DangerText>
        </Text>
        <Input
          placeholder={t("workspace.del.teamCode")}
          className="pl-[12px]"
          ref={inputRef}
          onChange={(e) => {
            if (e.target.value === workspaceDetail?.tenantId) {
              setVisible(false);
            }
          }}
        ></Input>
        <WarningText $visible={visible}>
          {t("workspace.del.warning")}
        </WarningText>
        <ButtonWrapper>
          <Button
            $color="#FFF"
            $solid="#555"
            onClick={onClose}
            $fontcolor={"#555555"}
          >
            {t("workspace.del.cancel")}
          </Button>
          <Button
            $color="#D20707"
            $solid="#D20707"
            $fontcolor={"#FFFFFF"}
            onClick={handleDelete}
          >
            {t("workspace.del.next")}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </ModalLayout>
  );
};

const WarningText = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? "block" : "none")};
  font-family: Noto Sans KR;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #d20707;
`;

const Wrapper = styled.div`
  display: flex;
  width: 330px;
  flex-direction: column;
  align-items: center;
`;

const DangerText = styled.div`
  padding-top: 13px;
  font-weight: 700;
  font-family: Noto Sans KR;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #d20707;
`;

const Input = styled.input`
  margin-top: 21px;
  width: 330px;
  height: 42px;
  border: 1px solid #c8cace;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;

  outline: none;

  &:focus {
    border-width: 2.5px;
    border-color: #d20707;
    transition: border-color 0.3s;
  }
`;

const Text = styled.div`
  color: #555;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 340px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 30px;
`;

const Button = styled.button<{
  $color: string;
  $solid: string;
  $fontcolor: string;
}>`
  display: flex;
  width: 100px;
  height: 42px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  font-size: 13px;

  border-radius: 4px;
  border: 1px solid ${(props) => props.$solid};
  background: ${(props) => props.$color};
  color: ${(props) => props.$fontcolor};
`;

export default WorkspaceDeleteModal;
