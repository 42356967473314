import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";

interface WorkspaceDetailState {
    workspaceInfo: WorkspaceInfoDTO | null;
    status: 'idle' | 'loading' | 'succeded' | 'failed';
    error: string | null;
}

const initialState: WorkspaceDetailState = {
    workspaceInfo: null,
    status: 'idle',
    error: null
}

interface ApiResponse {
    status: number;
    data: WorkspaceInfoDTO;
}

export const fetchWorkspaceDetailGET = createAsyncThunk(
    '/workspace/detail',
    async (id: string) => {
        const response = await axiosInstance.get(`/workspace/info?id=${id}`, requestAuth({}));
        return response.data;
    }
);

const workspaceDetailSlice = createSlice({
    name: 'workspaceDetail',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder
            .addCase(fetchWorkspaceDetailGET.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkspaceDetailGET.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = 'succeded';
                state.workspaceInfo = action.payload.data;
                
                if(typeof action.payload.data === 'number'){
                    state.error = 'NOT FOUND';
                }
            })
            .addCase(fetchWorkspaceDetailGET.rejected, (state) => {
                state.status = 'succeded';
                state.workspaceInfo = null;
            })
    }
});

export default workspaceDetailSlice.reducer;

