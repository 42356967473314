import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PaymentAgreedCheckbox = {
  value: boolean;
};

const initialState: PaymentAgreedCheckbox = {
  value: false,
};
export const paymentAgreedCheckboxSlice = createSlice({
  name: "paymentAgreedCheckboxSlice",
  initialState,
  reducers: {
    setChecked(state, action: PayloadAction<{ value: boolean }>) {
      state.value = action.payload.value;
    },
  },
});

export const { setChecked } = paymentAgreedCheckboxSlice.actions;
export default paymentAgreedCheckboxSlice.reducer;
