import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputCustom from "./Input";
import FormButton from "../Button/FormButton";
import SelectBox from "../Common/SelectBox";
import SelectBoxComponent from "../Common/SelectBox";
import { useWorkspaceForm } from "../../utils/yupCustom";
import { handleTabOnLastInput } from "../../utils/common/com.fun";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
interface Props {
  buttonText: string;
  goToNext: (dto: WorkspaceForm) => void;
  license: string;
}

const WorkspaceForm = ({ buttonText, goToNext, license }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useWorkspaceForm<WorkspaceForm>(license === "Free");
  const [region, setRegion] = useState<string>("KR");
  const isScreenSM = useMediaQuery({ maxWidth: 802 });
  const helperSM = `ml-[17px] bottom-[2px] top-[-6px] absolute bg-black rounded text-[#FFFFFF] text-[13px] flex items-center p-2 z-[100] z-10000`;
  const helperMD = `absolute bg-black rounded text-[#FFFFFF] text-[11.5px] flex items-center p-2 top-[19px] right-[-15px] z-[100] z-1000`;

  const onSubmitFun = (data: WorkspaceForm) => {
    goToNext({ ...data, region: region });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmitFun)}>
        <InputCustom
          text={t("workspace.form.teamName")}
          name="workspaceName"
          register={register}
          errors={errors}
        />
        <InputCustom
          text={t("workspace.form.teamCode")}
          iconVisible={true}
          name="tenantid"
          register={register}
          errors={errors}
          keyDownEvent={handleTabOnLastInput}
          tooltipClassName={
            isScreenSM
              ? `${helperMD} w-[320px] h-[48px] z-[100]`
              : `${helperSM} w-[380px] h-[48px]`
          }
          tooltipText={t("common.helper")}
        />
        {license !== "Free" && (
          <InputCustom
            text={t("workspace.personnelLimit")}
            iconVisible={true}
            name="personnelLimit"
            register={register}
            errors={errors}
            keyDownEvent={handleTabOnLastInput}
            tooltipClassName={
              isScreenSM
                ? `${helperMD} w-[320px] h-[58px]`
                : `${helperSM} w-[380px] h-[58px]`
            }
            tooltipText={t("workspace.tooltip")}
          />
        )}
        <SelectBoxWrapper>
          <SelectBoxComponent setStateProps={setRegion} />
        </SelectBoxWrapper>
        <FormButton
          text={buttonText}
          className="rlg:w-[395px] rmd:w-[337px] rsm:w-[297px]"
        ></FormButton>
      </Form>
    </>
  );
};

const SelectBoxWrapper = styled.div`
  width: 100%;
`;

const Form = styled.form`
  position: absolute;
  top: 15px;
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 20px;

  div {
    width: 100%;
  }

  div > div > input {
    height: 42px;
    width: 99%;
  }
  @media screen and (max-width: 590px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 340px) {
    padding: 0 20px;
  }

  #country-selected-box {
    width: 99%;
    height: 42px;
  }
`;

export default WorkspaceForm;
