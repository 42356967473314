import { useEffect, useRef, useState } from "react";
import { billingDataSet } from "../../utils/request";
declare global {
  interface Window {
    pay: (text: string) => void;
  }
}

interface Props {
  workspaceId: string;
}

const BillingForm = ({ workspaceId }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const inicisScriptTag = document.createElement("script");
    inicisScriptTag.src = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js";
    inicisScriptTag.type = "text/javascript";
    inicisScriptTag.async = true;
    document.body.appendChild(inicisScriptTag);

    inicisScriptTag.onload = () => {
      billingDataSet({ setLoading, workspaceId }).then((data) => {
        Object.entries(data as { [key: string]: string }).forEach(
          ([key, value]) => {
            if (formRef.current) {
              const inputEl = document.createElement("input");
              inputEl.type = "hidden";
              inputEl.name = key;
              inputEl.value = value;
              formRef.current.appendChild(inputEl);
            }
          }
        );
        window.INIStdPay.pay("form_pay");
      });
    };

    return () => {
      document.body.removeChild(inicisScriptTag);
    };
  }, []);
  return <form className="hidden" ref={formRef} id="form_pay"></form>;
};

export default BillingForm;
