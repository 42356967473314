import { click } from "@testing-library/user-event/dist/click";
import { useSelect } from "downshift";
import React, { useCallback, useEffect, useState } from "react";
import { css, styled } from "styled-components";
import axiosInstance from "../../utils/request/axios.instance";
import { useLocation, useNavigate } from "react-router-dom";
import { toastSuccess } from "../../utils/toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { fetchMemberInfo } from "../../redux/slice/memberSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

interface Props {
  items: string[];
  onChange: (selectedItem: string | null) => void;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  type: string;
  handleLogout?: (index: number) => void;
}

const DropDown = ({
  items,
  onChange,
  children,
  onClick,
  type,
  handleLogout,
}: Props) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<string>({
    items,
    initialSelectedItem: localStorage.getItem(`i18nextLng`),
    onSelectedItemChange(changes) {
      let temp = changes.selectedItem as string;

      if (temp === "Korean") {
        temp = "ko";
      } else if (temp === "Japanese") {
        temp = "ja";
      } else if (temp === "English") {
        temp = "en";
      } else {
        temp = "ko";
      }

      i18n.changeLanguage(temp);
      localStorage.setItem("i18nextLng", temp);
    },
  });

  const location = useLocation();
  const [langClick, setLangClick] = useState(false);
  const [loginClick, setLoginClick] = useState<boolean>(false);
  const [dropDownList, setDropDownList] = useState<string[]>([]);
  const [pathWorkspace, setPathWorkspace] = useState<boolean>();

  const handleLangClicked = () => {
    setLangClick((langClick) => !langClick);
  };
  const handleLoginClicked = () => {
    setLoginClick((loginClick) => !loginClick);
  };

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const currentPathWorkspace: boolean = location.pathname
      .split("/")
      .filter(Boolean)
      .includes("team");
    setPathWorkspace(currentPathWorkspace);
    setDropDownList(items);
  }, [location.pathname]);

  return (
    <div onClick={onClick ? onClick : undefined}>
      {type === "LANG" && (
        <span onClick={handleLangClicked}>
          <LangButton {...getToggleButtonProps()} $clicked={langClick}>
            {children}
          </LangButton>
        </span>
      )}
      {type === "LOGIN" && (
        <span onClick={handleLoginClicked} className="relative">
          <span {...getToggleButtonProps()}>{children}</span>
        </span>
      )}

      <ul
        {...getMenuProps()}
        style={{ display: isOpen ? "block" : "none" }}
        className={`absolute left-0 top-full m-0 p-0 list-none shadow-[0px_8px_16px_0px_rgba(0,0,0,0.2)] w-[134px] rounded ${
          type === "LOGIN" ? "ml-[92px]" : "ml-[6px]"
        } ${isOpen ? "block" : "hidden"}`}
      >
        {dropDownList.map((item, index) => {
          if (type === "LOGIN") {
            return (
              <MenuItem
                style={{
                  backgroundColor:
                    highlightedIndex === index ? "lightgray" : "white",
                }}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                onClick={() => {
                  if (handleLogout) {
                    handleLogout(index);
                  }
                }}
              >
                {item}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              style={{
                backgroundColor:
                  highlightedIndex === index ? "lightgray" : "white",
              }}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </MenuItem>
          );
        })}
      </ul>
    </div>
  );
};
interface ButtonProps {
  $clicked: boolean;
}

const LangButton = styled.button<ButtonProps>`
  border: 1px solid #1f62b9;
  color: #0000ff;
  padding: 8px 20px;
  border-radius: 25px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  gap: 6px;
  height: 36px;
  background-color: white;

  &:hover {
    background-color: ${(props) => (props.$clicked ? "#B8D3FE" : "#D9E5F9")};
  }
`;

const LoginButton = styled.button<ButtonProps>`
  margin-left: 9px;
  background-color: #1f62b9;
  position: relative;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0000ff;
  padding: 12px 25px;
  color: white;
  height: 36px;
  marginleft: 8px;
  border-radius: 25px;

  &:hover {
    background-color: ${(props) => (props.$clicked ? "#32418E" : "#599AEE")};
  }
`;

const MenuItem = styled.li`
  padding: 4px 12px;
  cursor: pointer;
  text-align: left;
  width: 134px;
  height: 28px;

  color: #000;
  text-align: left;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
`;

export default DropDown;
