import { useEffect, useState } from "react";


type TermsCheckboxState = {
    allCheck: boolean;
    privacyPolicy: boolean;
    termsOfService: boolean;
}

type TermsCheckboxStateWithoutAllCheck = Exclude<keyof TermsCheckboxState, 'allCheck'>;

const checkingNotAllCheck = (type: keyof TermsCheckboxState): type is TermsCheckboxStateWithoutAllCheck => {
    return type === 'privacyPolicy' || type === 'termsOfService';
}

const useTermsCheckbox = (): 
    [
        TermsCheckboxState, 
        (type: keyof TermsCheckboxState) => 
            (event: React.ChangeEvent<HTMLInputElement>) => void
    ] => {

    const [checkboxState, setCheckboxState] = useState<TermsCheckboxState>({
        allCheck: false,
        privacyPolicy: false,
        termsOfService: false
    });

    const updateChecboxState = (type: keyof TermsCheckboxState, checked:boolean) => {
        if(checkingNotAllCheck(type) && checkboxState.privacyPolicy && checkboxState.termsOfService){
            setCheckboxState(prev => ({...prev, [type]: checked, ['allCheck']: false}));
        }else if(type === 'allCheck' && checkboxState.allCheck){
            setCheckboxState({
                allCheck: false,
                privacyPolicy: false,
                termsOfService: false
            })
        }else{
            setCheckboxState(prev => ({...prev, [type]: checked}));
        }
    }

    const handleUpdateCheckBoxState = (type: keyof TermsCheckboxState) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            updateChecboxState(type, event.target.checked);
        }
    }

    useEffect(() => {
        if(checkboxState.allCheck){
            setCheckboxState({
                allCheck: true,
                privacyPolicy: true,
                termsOfService: true
            });
        }else if(checkboxState.privacyPolicy && checkboxState.termsOfService){
            setCheckboxState(prev => ({
                ...prev,
                allCheck: true
            }));
        }else{
            setCheckboxState(prev => ({
                ...prev,
                allCheck: false
            }));
        }
    }, [checkboxState.allCheck, checkboxState.privacyPolicy, checkboxState.termsOfService]);

    return [checkboxState, handleUpdateCheckBoxState];
}

export default useTermsCheckbox;
