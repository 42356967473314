import React from "react";
import styled from "styled-components";

interface Props {
    text: string;
    educational?: boolean;
}

interface StyledProps{
    $bgColor?: string;
}

const backgroundColor = (text: string) => {
    if(text === "Free"){
        return '#4067F6';
    }else if(text === "Standard"){
        return '#161DC4';
    }else if(text === "Enterprise"){
        return '#9C52E6';
    }
}

const ProductCard = ({ text, educational }: Props) => {

    return (
        <Container $bgColor={backgroundColor(text)}>
            <Text>
                {text}
                {
                    educational
                    &&
                    "(教育用)"
                }
            </Text>
        </Container>    
    );
}

const Container = styled.div<StyledProps>`
    width: 148px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 4px;
    background: ${props => props.$bgColor};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Text = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export default ProductCard;
