import React, { useState } from "react";
import { styled } from "styled-components";
import MainLayout from "../../components/Layout/MainLayout";
import InputCustom from "../../components/Form/Input";
import FormButton from "../../components/Button/FormButton";
import { Link } from "react-router-dom";
import { useCustomForm, useEmailSendForm } from "../../utils/yupCustom";
import axiosInstance from "../../utils/request/axios.instance";
import PwdMailModal from "../../components/Modal/PwdMailModal";
import { useTranslation } from "react-i18next";
import { toastError } from "../../utils/toast";
import { AxiosError } from "axios";
import Loader from "../../components/Loader";

interface SubmitType {
    email: string;
}

const PasswordFindPage = () => {

    const { t } = useTranslation();
    const contentArr = [
        t('pwd.emailSend'),
        t('pwd.emailNotFound'),
        t('pwd.noAuthenticated')
    ]

    const { register, handleSubmit, formState: { errors }} = useEmailSendForm<PasswordFindDTO>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [contentIndex, setContentIndex] = useState<number>(0);    //0: 이메일 전송, 1: 등록되지 않은 이메일
    const [loading, setLoading] = useState<boolean>(false);

    const fetchEmail = ({email}: SubmitType) => {
        setLoading(true);
        axiosInstance.post('/member/pwd/find', { email: email })
            .then((data) => {
                setLoading(false);
                setContentIndex(0);
                setModalOpen(true);
            })
            .catch((err) => {
                setLoading(false);
                if(err.response.status === 404){
                    setContentIndex(1);
                    setModalOpen(true);
                }else if(err.response.status === 400){
                    setContentIndex(1);
                    setModalOpen(true);
                }else{
                    toastError('SERVER ERROR');
                }
            });
    }

    return (
        <>
            <PwdMailModal isOpen={modalOpen} onClose={() => { setModalOpen(false) }} contentIndex={contentIndex} className="w-[410px] text-[17px] sm:w-[210px] sm:text-[13px]"/>
            <MainLayout imgSrc={"/images/pwd_bg.png"}>
                <Wrapper>
                   {
                    loading
                    ?
                    <Loader/>
                    :
                    <>
                        <H3>{t('pwd.forgatPassword')}</H3>
                        <PwdText>{t('pwd.emailSendText')}</PwdText>
                        <Form onSubmit={handleSubmit(fetchEmail)}>
                            <InputCustom text={t('common.email')} name="email" register={register} errors={errors}/>
                            <FormButton text={t('common.send')} className="w-[314px] sm:w-[290px]"/>
                        </Form>

                        <Link to={'/login'} dangerouslySetInnerHTML={{ __html : t('pwd.backToLogin')}}></Link>
                        <Link to={'/join'} dangerouslySetInnerHTML={{ __html: t('pwd.firstVisit')}}></Link>
                    </>
                   }
                </Wrapper>
            </MainLayout>
        </>
    )

}

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;

    a{
        margin-top: 10px;
        text-decoration-line: none;
        color: #1F62B9;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    @media (max-width: 590px) {
        align-items: center;
    }

`;

const H3 =styled.h3`
    height: 27px;

    color: #000;
    font-family: Noto Sans KR;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;

    @media (max-width: 590px) {
        font-size: 22px;
        margin-bottom: 10px;
    }
`;

const PwdText = styled.div`
    color: #555;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 590px){
        font-size: 10px;
        width: 270px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-bottom: 20px;
    width: 320px;
    gap: 11px;
    div > div {
        width: 314px;
    }
    .template-input{
        width: 100%;
    }

    @media (max-width: 590px){
        margin-top: 20px;
        width: 290px;

        div > div {
            width: 290px;
        }
    }
`;

export default PasswordFindPage;
