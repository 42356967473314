import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { error } from "console";
import { useEffect, useRef, useState } from "react";
import { toastError, toastSuccess } from "../toast";

const axiosInstance: AxiosInstance = (() => {
  const domain = window.location.hostname;
  let region = "KO";
  if (domain === "localhost") {
    region = "JP";
  }
  return axios.create({
    // baseURL: "http://localhost:4000/api",
    baseURL: "https://portal.schoolline.jp/api",
    // baseURL: "http://192.168.0.124/api",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Region": region,
    },
  });
})();

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      window.dispatchEvent(new CustomEvent("unauthorized"));
    }
    return Promise.reject(error);
  }
);

export const requestAuth = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const headers: any = {
    "Content-Type": "application/json; charset=utf-8",
    ...config.headers,
  };

  const token = sessionStorage.getItem("uc-portal-token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return {
    ...config,
    headers,
  };
};

export const requestUnAuth = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const domain = window.location.hostname;

  const headers: any = {
    "Content-Type": "application/json; charset=utf-8",
    ...config.headers,
  };

  headers["Authorization"] = `Bearer connect`;
  return {
    ...config,
    headers,
  };
};

export default axiosInstance;
