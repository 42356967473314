import { useTranslation } from "react-i18next";
import { toastSuccess } from "../../utils/toast";
import DetailButton from "../Button/DetailButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Button from "../../ui/Button";
import Text from "../../ui/Text";
import BillingForm from "../BillingForm";
interface Props {
  workspaceId: string | undefined;
  creditCardNumber: string;
}

const CreditCardInfo = ({ creditCardNumber, workspaceId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const workspaceDetail = useSelector(
    (state: RootState) => state.workspaceDetail.workspaceInfo
  );
  const handleBillingFormVisible = () => {
    setIsVisible((bool) => !bool);
  };

  useEffect(() => {}, []);

  if (!workspaceId) return null;

  return (
    <>
      <div className="flex flex-col font-noto-sans-kr font-normal leading-[20.27px] text-[14px] text-[#555555] sm:text-[11px]">
        {creditCardNumber ? (
          <>
            <span>{t("detail.creditCardRegistration")}</span>
            <span>{creditCardNumber}</span>
          </>
        ) : (
          <>
            <span>{t("detail.noRegisteredCard")}</span>
          </>
        )}
      </div>
      <Button variant="blue" onClick={handleBillingFormVisible}>
        <Text>{t("detail.cardChange")}</Text>
      </Button>
      {isVisible && <BillingForm workspaceId={workspaceId} />}
    </>
  );
};

export default CreditCardInfo;
