import React from "react";
import styled from "styled-components";
import { LoginImage } from "../../icons";

interface Props {
    children?: React.ReactNode;
    imgSrc?: string;
}

const MainLayout = ({children, imgSrc}: Props) => {
    return (
            <Container>
                <LoginWrapper> {/* 로그인 */}
                    {children}
                </LoginWrapper>

                <ImageWrapper> {/* 이미지*/}
                    <Image>
                        <img src={imgSrc} className="w-[340px] h-[440px] min-w-[340px] min-h-[440px]"/>
                    </Image>
                </ImageWrapper>
            </Container>
        )
}

const Image = styled.div`
    background: linear-gradient(163deg, #E4F7FD 10.53%, #FAF5FF 93.56%);
    width: 100%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content:center;
    padding-top: 30px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const ImageWrapper = styled.div`
    float: right;
    height: 100%;
    position: absolute;
    right: 0;

    width: 30%;
    opacity: 1;
    transition: opacity 4s ease-out;
    overflow: hidden; 
    
    @media (max-width: 1050px) {
        opacity: 0;
    }
    @media (max-width: 950px) {
        display: none !important;
    }
`;

const LoginWrapper = styled.div`
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;

    @media (max-width: 950px) {
       width:100%;
    }
`;

export default MainLayout;
