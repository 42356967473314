import React from "react";
import styled from "styled-components";

const ErrorPage = () => {
    return (
        <Container>
           <Img src={"/images/error.png"}></Img>

           <Text>404 ERROR</Text>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Img = styled.img`
    
`;

const Text = styled.div`
    color: #000;
    margin-top: 20px;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    margin-bottom: 200px;
`;

export default ErrorPage;
