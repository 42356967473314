import { useTranslation } from "react-i18next";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";

interface Props {
  lastPaymentDate: string;
  nextPaymentDate: string;
  licenseType: string;
}

const RemainingDays = ({
  lastPaymentDate,
  nextPaymentDate,
  licenseType,
}: Props) => {
  const { t } = useTranslation();
  const {
    getDifferenceInDays,
    getFormattedDate,
    getFreeDate,
    getCurrentDifferenceDays,
  } = useCalculateAndFormatDates();

  return (
    <>
      {licenseType === "Free" ? (
        <span>{`${getFreeDate(nextPaymentDate)}${t("detail.days")}`}</span>
      ) : (
        <span>{`${getFormattedDate(nextPaymentDate)}(${getCurrentDifferenceDays(
          nextPaymentDate
        )} ${t("detail.days")})`}</span>
      )}
    </>
  );
};

export default RemainingDays;
