import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import Workspace from "./pages/Workspace";
import { styled } from "styled-components";
import WorksapceCreatePage from "./pages/WorkspaceCreatePage";
import LoginPage from "./pages/Login";
import MailSuccessPage from "./pages/Auth/MailSuccess";
import AuthSuccessPage from "./pages/Auth/AuthSuccessPage";
import PasswordFindPage from "./pages/Auth/PasswordFindPage";
import PasswordChangePage from "./pages/Auth/PasswordChangePage";
import PasswordSuccessPage from "./pages/Auth/PasswordSuccess";
import ErrorPage from "./pages/Error";
import WorkspaceDetail from "./pages/WorkspaceDetail";
import JoinPage from "./pages/Join";
import { ToastContainer } from "react-toastify";
// import Menu from "./components/Menu/Menu";
import SuccessPage from "./pages/WorkspaceSuccessPage";
import TokenCheck from "./components/TokenCheck";
import Menu from "./components/Menu";
import MainPage from "./pages/Main";
import StorageAddPage from "./pages/StorageAdd";
import MemberAddPage from "./pages/MemberAdd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardRegistration from "./pages/CardRegistration";
import { BigHourglass } from "./icons";
import PaymentMethodRegistrationPage from "./pages/PaymentMethodRegistration";
import Button from "./ui/Button";
import PaymentAdmin from "./pages/PaymentAdmin";
import BillingFormClose from "./components/BillingFormClose";
import BillingAdditionalModal from "./components/Modal/BilllingAdditionalModal";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/uiTest" element={<SuccessPage />}></Route>
        </Route>

        {/* <Route path='token' element={<TokenCheck/>}/> */}
        <Route element={<PageLayout />}>
          <Route path="/team" element={<Workspace />}></Route>
          {/* <Route path="/payment" element={<PaymentAdmin />}></Route> */}
          <Route path="/detail/:id" element={<WorkspaceDetail />}></Route>
        </Route>

        <Route element={<LoginLayout />}>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/mail" element={<MailSuccessPage />}></Route>
          <Route path="/auth" element={<AuthSuccessPage />}></Route>
          <Route path="/join" element={<JoinPage />}></Route>

          <Route path="/mail/pwd" element={<PasswordFindPage />}></Route>
          <Route path="/pwd/change" element={<PasswordChangePage />}></Route>
          <Route path="/pwd/success" element={<PasswordSuccessPage />}></Route>
        </Route>

        <Route
          path="/workspace/set-up"
          element={<WorksapceCreatePage />}
        ></Route>
        <Route path="/workspace/success" element={<SuccessPage />}></Route>
        <Route path="/workspace/storage" element={<StorageAddPage />}></Route>
        <Route path="/workspace/member" element={<MemberAddPage />}></Route>
        <Route path="/workspace/card" element={<CardRegistration />}></Route>

        <Route
          path="/workspace/pay"
          element={<PaymentMethodRegistrationPage />}
        ></Route>

        {/* <Route path='/hourglass' element={<HourglassComponent/>}></Route> */}
        {/* <Route path="/close" element={<BillingFormClose />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}
const UiTestPage = () => (
  <div className="w-screen h-screen flex items-center justify-center">
    <BillingAdditionalModal />
  </div>
);

function HourglassComponent() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <img
        className="rounded-[1000px]"
        src={`${process.env.PUBLIC_URL}/images/hourglass.gif`}
      />
    </div>
  );
}

function LoginLayout() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <Menu isOpen={isMenuOpen} toggleMenu={closeMenu} />
      <Outlet />
    </>
  );
}

function PageLayout() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <Menu isOpen={isMenuOpen} toggleMenu={closeMenu} />
      <Container>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}

function MainLayout() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <Menu isOpen={isMenuOpen} toggleMenu={closeMenu} />
      <Container>
        <Outlet />
      </Container>
      {/* <Footer/> */}
    </>
  );
}

const Container = styled.div`
  padding: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
`;

const Spaner = styled.div`
  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      0%,
      25% {
        transform: rotate(0deg); /* 애니메이션 시작과 25% 지점에서 회전 각도 */
      }
      50% {
        transform: rotate(360deg); /* 50% 지점에서 완전히 회전 */
      }
      50.01%,
      100% {
        transform: rotate(
          360deg
        ); /* 50.01%부터 100%까지 동일한 각도 유지 (정지 상태) */
      }
    }
  }
`;

const BottomHourGlass = styled.span`
  height: 42px;
  width: 60px;
  background-color: #1f62b9;
  border-radius: 200px 200px 0 0;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -28px;
  animation: growAndShrink 2s infinite;
  @keyframes growAndShrink {
    0%,
    50% {
      /* 0%에서 50%까지는 첫 2초 동안 애니메이션 실행 */
      opacity: 1;
      height: 43px;
      width: 60px;
      left: 0px; /* 초기 left 위치 */
    }
    25% {
      /* 25% 지점에서 애니메이션의 최종 상태에 도달 */
      opacity: 0;
      height: 0px;
      width: 31px;
      left: 69px;
    }
    50%,
    100% {
      /* 50%에서 100%까지는 애니메이션이 멈춘 상태 유지 */
      opacity: 0;
      height: 0px;
      width: 31px;
      left: 69px;
    }
  }
`;

const TopHourGlass = styled.span`
  height: 42px;
  width: 60px;
  background-color: #1f62b9;
  border-radius: 0 0 200px 200px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 10%;
  margin-left: -28px;
  animation: growAndShrinkBottom 2s infinite;
  @keyframes growAndShrinkBottom {
    0% {
      height: 0px;
    }
    100% {
      opacity: 0;
      height: 43px; /* 애니메이션 시작과 끝에서 박스의 높이 */
      width: 60px;
    }
  }
`;

const BlueBall = styled.span`
  height: 20px;
  width: 20px;
  background-color: #1f62b9;
  border-radius: 100%;
  animation: drop 2s infinite;
  @keyframes drop {
    from {
      top: 0; /* 애니메이션 시작 위치 */
    }
    to {
      top: 90%; /* 애니메이션 끝 위치 */
    }
  }
`;

export default App;
