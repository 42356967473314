import React from "react";
import { toast } from "react-toastify";

export const toastSuccess = (message: string) => {
  // toast.dismiss();

  toast.success(message, {
    className: "success-toast",
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      width: "100%",
      textAlign: "center",
      backgroundColor: "green",
    },
  });
};

export const toastError = (message: string) => {
  // toast.dismiss();

  toast.error(message, {
    className: "error-toast",
    position: "top-center", // Toast를 화면의 상단 중앙에 위치시킴
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "error",
    style: {
      width: "100%", // 너비를 80%로 설정
      backgroundColor: "#ff0000", // 배경색을 빨간색으로 변경
      textAlign: "center", // 텍스트를 가운데 정렬
    },
  });
};
