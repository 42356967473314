import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import { styled } from "styled-components";
import InputCustom from "../../components/Form/Input";
import FormButton from "../../components/Button/FormButton";
import { usePasswordChangeForm } from "../../utils/yupCustom";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/request/axios.instance";
import { toastError, toastSuccess } from "../../utils/toast";
import { hashSHA256 } from "../../utils/sha256";
import { useTranslation } from "react-i18next";

const PasswordChangePage = () => {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }} = usePasswordChangeForm<PasswordChangeDTO>();

    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const code: string = searchParam.get('code')!;
    const navigate = useNavigate();

    const fetchAuth = () => {
        if(code) {
            axiosInstance.get('/member/pwd/auth', { params: {code}})
                .catch((err) => {
                    toastError(t('common.wrongApproach'));
                    navigate('/');
                })
        }else{
            toastError(t('common.wrongApproach'));
            navigate('/');
        }
    }

    const fetchPwdChange = ({ password, passwordConfirm } : PasswordChangeDTO) => {
        if(code){
            hashSHA256(password).then((hashPwd) => {
                axiosInstance.put('/member/pwd/change', {
                    code: code,
                    password: hashPwd
                }).then((data) =>{
                    toastSuccess(t('pwd.change.success'));
                    navigate('/login');
                }).catch((errors) => {
                    toastSuccess(t('pwd.change.fail'));
                })
            });
        }
    }

    useEffect(() => {
        fetchAuth();
    },[]);

    return (
        <MainLayout imgSrc={"/images/pwd_bg.png"}>
            <Wrapper>
                <H3 $textSize={i18n.language === 'ja' ? '31px' : '36px'} dangerouslySetInnerHTML={{
                    __html: t('pwd.change.newPwdText')
                }}></H3>
                <PwdText $textSize={'14px'}>{t('pwd.change.inputText')}</PwdText>

                <Form onSubmit={handleSubmit(fetchPwdChange)}>
                    <InputCustom text={t('pwd.change.text')} name="password" register={register} errors={errors}/>
                    <InputCustom text={t('pwd.change.textConfirm')} name="passwordConfirm" register={register} errors={errors}/>
                    <FormButton text={t('pwd.change.textChange')} className="w-[315px]"/>
                </Form>
            </Wrapper>
        </MainLayout>
    )
}

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
`;

const H3 =styled.h3<{ $textSize: string}>`
    height: 80px;
    color: #000;
    font-family: Noto Sans KR;
    font-size: ${props => props.$textSize};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 320px;
    margin-bottom: 19px;

    @media (max-width: 590px) {
        font-size: 22px;
        margin-bottom: 0px;
    }
`;
const PwdText = styled.div<{ $textSize: string}>`
    color: #555;
    font-family: Noto Sans KR;
    font-size: ${props => props.$textSize};
    font-style: normal;
    width: 320px;
    font-weight: 400;
    line-height: normal;
`;

const Form = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
    margin-bottom: 30px;
    width: 320px;
    gap: 36px;
    div > div {
        width: 314px;
    }
    .template-input{
        width: 100%;
    }

    @media (max-width: 590px){
        width: 290px;
        div > div {
            width: 290px;
        }
    }
    
`;
export default PasswordChangePage;
