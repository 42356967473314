

interface Props {
    licenseType: string;
    educational?: boolean;
}

const LicenseDetailCard = ({ licenseType, educational }: Props) => {
    return (
        <span className="text-[18px] text-[#1F62B9] font-semibold font-noto-sans-kr leading-[26.06px]">
            {licenseType}
            {educational && 
                (
                    "(教育用)"
                )
            }
        </span>
    )
}

export default LicenseDetailCard;
