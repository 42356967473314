import React from "react";
import {useTranslation} from "react-i18next";
import {
    CalFeatureIcon,
    MemberFeatureIcon,
    MsgFeatureIcon,
    ProjectFeatureIcon,
    TalkFeatureIcon,
    TodoFeatureIcon,
    VideoFeatureIcon
} from "../../icons";
interface Props {
    title: string;
}

const FeatureItem = ({ title }:Props) => {

    const { t } = useTranslation();
    const data = labels[title];

    return (
        <div className="w-[260px] h-[243px] flex flex-col items-center gap-6 md:flex-row md:w-[318px] md:h-[140px]">
            {data.icon}

            <div className="space-y-3 md:space-y-3 w-[290px]">
                <p className="font-bold text-[24px] text-[#2D2D2D] font-sans-kr text-center md:text-left">
                    {t(data.title)}
                </p>
                <div className="text-center leading-[26.06px] font-sans-kr font-semibold text-[#5D5D5D] md:text-left md:text-[14px]"
                    dangerouslySetInnerHTML= {{ __html: t(data.subTitle) }}
                >
                </div>
            </div>
        </div>
    )
}

interface featureType {
    [key:string] : LabelItem;
}
const labels: featureType =
{
    "member": {
        icon: <MemberFeatureIcon/>,
        title: "feature.member",
        subTitle: "feature.sub.member"
    },
    "talk": {
        icon: <TalkFeatureIcon/>,
        title: "feature.talk",
        subTitle:"feature.sub.talk"
    },
    "msg": {
        icon: <MsgFeatureIcon/>,
        title: "feature.msg",
        subTitle: "feature.sub.msg"
    },
    "video": {
        icon: <VideoFeatureIcon/>,
        title: "feature.video",
        subTitle: "feature.sub.video"
    },
    "todo": {
        icon: <TodoFeatureIcon/>,
        title: "feature.todo",
        subTitle: "feature.sub.todo"
    },
    "project":  {
        icon: <ProjectFeatureIcon/>,
        title: "feature.project",
        subTitle: "feature.sub.project"
    },
    "calendar":  {
        icon: <CalFeatureIcon/>,
        title: "feature.calendar",
        subTitle: "feature.sub.calendar"
    }
};

export default FeatureItem;
