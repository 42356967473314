import { useEffect, useRef, useState } from "react";
import { BottomArrow } from "../../icons";
import { useTranslation } from "react-i18next";

const storages = [1, 2, 3, 4, 5, 6,7,8,9,10];

interface Props {
    onChange: (storage:number) => void;
    storage: number;
}

const StorageSelectBox = ({ onChange, storage }: Props) => {
    const [selectedCapacity, setSelectedCapacity] = useState<number>(0);
    const [selectedBoxOpen, setSelectedBoxOpen] = useState<boolean>(false);
    
    const { t } = useTranslation();
    const boxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(selectedBoxOpen){
            setSelectedBoxOpen(false);
        }

        const handleActive = (e: MouseEvent) => {
            if(boxRef.current && !boxRef.current.contains(e.target as HTMLElement)){
                setSelectedBoxOpen(false);
            }
        }

        document.addEventListener("mouseup", handleActive);

        return () => { document.removeEventListener("mouseup", handleActive); }
    },[storage]);

    return (
        <div className="flex flex-row items-center gap-3">
            <span>{t('storage.month')}</span>

            <div className="w-[100px] h-[42px] rounded-[4px] border-[1px] border-solid flex items-center relative pl-3"
                onClick={() => { setSelectedBoxOpen((isOpen) => !isOpen) }}
                ref={boxRef}
            >

            <span>{storage}</span>

            <span className={`absolute right-2 transition duration-300 ${selectedBoxOpen ? 'rotate-180' : 'rotate-0'}`}>
                <BottomArrow/>
            </span>

            <ul className={`h-[auto] w-[100px] rounded-[10px] absolute top-[42px] bg-[#FFF] left-0 shadow-select-options ${!selectedBoxOpen ? 'hidden' : ''}`}>
                {
                    storages.map((num) => (
                        <li className="h-8 pl-3 pt-1 hover:bg-slate-100 text-[14px]"
                            key={num}
                            onClick={() => { 
                                onChange(num);
                            }}
                        >
                            {num}
                        </li>
                    ))
                }
            </ul>
            </div>

            <div>
                TB
            </div>
        </div>
    )
}

export default StorageSelectBox;
