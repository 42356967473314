import React from 'react';
import styled from 'styled-components';


interface Props {
    setChecked: any;
    checked: boolean;
}

const Checkbox = ({setChecked, checked}: Props) => {
    
  return (
    <CheckboxContainer>
      <StyledCheckbox checked={checked} onClick={setChecked}/>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: flex; /* Flex 컨테이너 적용 */
  align-items: center; /* 세로 축 중앙 정렬 */
  cursor: pointer;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: all 150ms;
  background-image: url('${props => props.checked ? `${process.env.PUBLIC_URL}/images/check_box_checked.png` : `${process.env.PUBLIC_URL}/images/check_box_unchecked.png`}');
  background-size: contain;
`;

const CheckboxLabel = styled.span`
  margin-left: 10px; /* 체크박스와 라벨 사이의 간격 조정 */
`;
export default Checkbox;
