import React from "react";
import FeatureItem from "../FeatrueItem";
import { CalFeatureIcon, MemberFeatureIcon, MsgFeatureIcon, ProjectFeatureIcon, TalkFeatureIcon, TodoFeatureIcon, VideoFeatureIcon } from "../../../icons";

const Features = () => {

    const labels: string[] = [
        "member",
        "talk",
        "msg",
        "video",
        "todo",
        "project",
        "calendar"
    ]

    return (
        <>
            <div className="py-[100px] space-y-2 md:space-y-0">
                <div className="float flex flex-row justify-center items-center gap-9 md:flex-col md:gap-4">
                    {labels.slice(0,4).map((item) => (
                        <FeatureItem title={item} key={item}/>
                    ))}
                </div>

                <div className="flex flex-row justify-center items-center gap-6 md:flex-col md:gap-4">
                    {labels.slice(4).map((item) => (
                        <FeatureItem title={item} key={item}/>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Features;
