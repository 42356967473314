import { ReactElement, useEffect, useState } from "react";
import { BillingAdditionalForm, ReactHookFormProps } from "../../@types";
import { Controller, ControllerRenderProps } from "react-hook-form";
import Button from "../../ui/Button";

const BillingAdditionalInput = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
  renderInput,
  name,
}: ReactHookFormProps<BillingAdditionalForm> & {
  renderInput: (
    field: ControllerRenderProps<
      BillingAdditionalForm,
      keyof BillingAdditionalForm
    >
  ) => ReactElement;
  name: keyof BillingAdditionalForm;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  // useEffect(() => {}, [editMode]);
  // if (!editMode) {
  //   return (
  //     <div className="h-[69.6px] w-full flex justify-between">
  //       <div>데이터</div>
  //       <Button
  //         variant="blue"
  //         onClick={() => {
  //           setEditMode(true);
  //         }}
  //       >
  //         변경
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <Controller<BillingAdditionalForm, keyof BillingAdditionalForm>
      key={name as string}
      name={name}
      control={control}
      render={({ field }) => renderInput(field)}
    />
  );
};

export default BillingAdditionalInput;
