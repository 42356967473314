import { ReactNode, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const PriceSkeleton = ({
  price,
  isFetching,
}: {
  price: number;
  isFetching: boolean;
}) => {
  useEffect(() => {}, [isFetching]);

  return (
    <>
      {isFetching ? (
        <Skeleton
          width={80}
          height={20}
          style={{ marginLeft: "5px", marginRight: "5px" }}
        />
      ) : (
        new Intl.NumberFormat("ko-KR").format(price)
      )}
    </>
  );
};

export default PriceSkeleton;
