import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { AxiosError } from "axios";

interface PaymentHistoryDTO {
    id: number;
    payemntId: number;
    price: string;
    paymentDetail: string;
    createDate: string;
    monetaryUnit: string;
}

interface State {
    status:  'loading' | 'succeeded' | 'failed';
    data: PaymentHistoryDTO[];
}

const initialState: State = {
    status: 'loading',
    data: []
}

interface ApiResponse {
    status: number;
    data: PaymentHistoryDTO[];
}

export const fetchPaymentHistoryGET = createAsyncThunk('workspace/payment/history',
    async (tenantId: string) => {
        try{
            const response = await axiosInstance.get('/workspace/payment/history?tenantId='+tenantId, requestAuth({}));
            return response.data;
        }catch(error){
            if(error instanceof AxiosError){
                return error.response?.status;
            }
            return -1;
        }
    }
)

export const paymentHistorySlice = createSlice({
    name: 'paymemtHistory',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentHistoryGET.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentHistoryGET.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
                state.status = 'succeeded';

                if(action.payload){
                    state.data = action.payload.data;

                    state.data = state.data.map((en) => {
                        const { monetaryUnit, ...rest} = en; 
                        return {
                            monetaryUnit: en.monetaryUnit === 'WON' ? '원' : "¥",
                            ...rest
                        }
                    })
                }
            })
            .addCase(fetchPaymentHistoryGET.rejected, (state) => {
                state.status = 'failed';

            })
    }
})

export default paymentHistorySlice.reducer;
