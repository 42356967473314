import { createSlice } from "@reduxjs/toolkit";

interface BillingAdditionalModalState {
  isOpen: boolean;
}

const initialState: BillingAdditionalModalState = {
  isOpen: false,
};

const billingAdditionalModalSlice = createSlice({
  name: "billingAdditionalModalSlice",
  initialState,
  reducers: {
    modalOpen(state) {
      state.isOpen = true;
    },
    modalClose(state) {
      state.isOpen = false;
    },
  },
});

export const { modalOpen, modalClose } = billingAdditionalModalSlice.actions;
export default billingAdditionalModalSlice.reducer;
