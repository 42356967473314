import clsx from "clsx";
import Loader from "../../components/Loader";
import Text from "../../ui/Text";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import TypingAnimation from "../../components/Animation/TextCursor";
import { useTranslation } from "react-i18next";

interface Props {
  paymentLoading: boolean;
  license: "Free" | "Standard" | "Enterprise" | "NONE";
}

const PaymentLoadingAnimation = ({ paymentLoading, license }: Props) => {
  const { t } = useTranslation();
  const textArray: string[] =
    license === "Free"
      ? [t("common.paymentWait"), t("common.workspaceWait")]
      : [t("common.workspaceWait"), t("common.workspaceWait")];
  useEffect(() => {}, [paymentLoading]);
  return (
    <div
      className={clsx(
        "fixed w-full flex items-center justify-center flex-col gap-8 bg-[rgba(0,0,0,.8)] inset-0 z-[600] overflow-hidden transform transition-transform duration-500",
        {
          hidden: !paymentLoading,
        }
      )}
    >
      {paymentLoading && (
        <TypingAnimation
          texts={textArray}
          typingSpeed={380}
          deletingSpeed={180}
          pauseDuration={1700}
        />
      )}
      <Loader />
    </div>
  );
};

export default PaymentLoadingAnimation;
