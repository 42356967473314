import { useEffect, useState } from "react";
import AddHeader from "../../components/Add/AddHeader";
import StorageSelectBox from "../../components/Add/StorageSelectBox";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError } from "../../utils/toast";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import Skeleton from "react-loading-skeleton";
import { error } from "console";
import { AxiosError } from "axios";
import useCalucalateUsersCost from "../../hooks/use.calculate.users.cost";
import useModifyUsers from "../../hooks/use.modify.users.size";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

interface LocationState {
  tenantId?: string;
  nextPaymentDate: string;
}

const MemberAddPage = () => {
  const [value, setValue] = useState<number>(1);
  const [dto, setDTO] = useState<PriceDTO>();
  const [monetaryUnit, setMonetaryUnit] = useState<string>("원");
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const state: LocationState = location.state as LocationState;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [totalPrice, VAT, costWithoutVAT] = useCalucalateUsersCost(
    value,
    dto?.licensePrice!,
    state.nextPaymentDate
  );
  const [increaseNumberOfUsers, putLoading] = useModifyUsers();

  const fetchUsersAddPUT = () => {
    increaseNumberOfUsers({
      tenantId: state.tenantId!,
      usersSizeToAdd: value,
      totalPrice: totalPrice,
    });
  };

  useEffect(() => {
    if (!state || !state.tenantId || !state.nextPaymentDate) {
      toastError(t("join.auth.fail"));
      navigate("/");
    }
    setLoading(true);
    axiosInstance
      .get(
        "/workspace/payment/add/price",
        requestAuth({
          params: {
            tenantId: state.tenantId,
          },
        })
      )
      .then((result) => {
        setDTO(result.data.data);
        setLoading(false);

        setMonetaryUnit(
          result.data.data.monetaryUnit === "WON" ? t("add.unit") : "¥"
        );
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          navigate("/");
        }
      });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^[0-9]*$/.test(newValue)) {
      const tempValue = parseInt(newValue, 10);

      if (tempValue > 100) return;

      setValue(tempValue || 0);
    }
  };

  return (
    <>
      {putLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <article>
          <AddHeader />
          <section className="w-full flex flex-col items-center">
            <div className="w-[504px] flex flex-col items-center gap-4">
              <div className="w-full font-noto-sans-kr text-[14px] font-normal leading-[20.27px] space-y-2 text-[#555555] mb-[44px]">
                <h3 className="text-[36px] leading-[52.13px] font-noto-sans-kr text-center font-bold mb-9">
                  {t("add.title")}
                </h3>

                <div className="flex items-center gap-3">
                  <input
                    dir="rtl"
                    className="w-[100px] h-[42px] border-[1px] border-[#C8CACE] rounded-[4px] border-solid relative text-right px-3"
                    onChange={handleChange}
                    value={value}
                  />
                  {t("add.subTitle")}
                </div>
                <div>{t("add.subTitle2")}</div>
              </div>

              <div className="w-full flex justify-between">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {t("add.productPrice")}
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {loading ? (
                    <Skeleton width={190} height={15}></Skeleton>
                  ) : (
                    ` ${new Intl.NumberFormat("ko-KR").format(
                      costWithoutVAT
                    )} ${monetaryUnit}`
                  )}
                </span>
              </div>

              <div className="w-full flex justify-between">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  VAT(10%)
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {loading ? (
                    <Skeleton width={190} height={15}></Skeleton>
                  ) : (
                    `${new Intl.NumberFormat("ko-KR").format(
                      VAT
                    )} ${monetaryUnit}`
                  )}
                </span>
              </div>

              <div className="border-solid w-full border-[1px] border-[#555555]"></div>

              <div className="w-full flex justify-between mb-3">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {t("add.totalPrice")}
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {loading ? (
                    <Skeleton width={140} height={15}></Skeleton>
                  ) : (
                    `${new Intl.NumberFormat("ko-KR").format(
                      totalPrice
                    )} ${monetaryUnit}`
                  )}
                </span>
              </div>

              <div className="flex flex-col w-full font-noto-sans-kr font-normal text-[14px] leading-[20.27px] text-[#555555] mb-5">
                <span>
                  {t("add.memberPriceFront")} <>&nbsp;</>
                  <strong>
                    <>
                      {loading ? (
                        <Skeleton width={80} height={15} inline></Skeleton>
                      ) : (
                        new Intl.NumberFormat("ko-KR").format(totalPrice) +
                        monetaryUnit
                      )}
                    </>
                  </strong>
                  {t("add.memberPriceBack")}
                </span>
                {/* <span>다음 결제인 {getFormattedDateLine(state.nextPaymentDate, 'ko-KR')}은  <>&nbsp;</>
                                    <strong>
                                        {new Intl.NumberFormat('ko-KR').format((getCurrentDifferenceDays(state.nextPaymentDate) * (dailyPrice)) + Number(dto?.licensePrice) * Number(dto?.personnelLimit)) + dto?.monetaryUnit}
                                    </strong>
                                입니다.</span> */}
              </div>

              <button
                className="w-[320px] h-[42px] bg-[#1F62B9] rounded-[4px] text-[#FFF] leading-[23.17px] text-[16px] font-bold"
                onClick={fetchUsersAddPUT}
              >
                {t("add.memberAddBtn")}
              </button>
            </div>
          </section>
        </article>
      )}
    </>
  );
};

export default MemberAddPage;
