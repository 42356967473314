import { useTranslation } from "react-i18next";
import {CompanyBlueIcon, CompanyWhiteIcon, EducationBlueIcon, EducationWhiteIcon} from "../../icons";

interface Props {
    onClick: (state: "EDUCATION" | "COMPANY") => void;
    selectState: "EDUCATION" | "COMPANY";
}

const SelectionNavigator = ({ selectState, onClick }: Props) => {

    const { t } = useTranslation();

    const educationSelected: boolean = (selectState === "EDUCATION");
    const companySelected: boolean = (selectState === "COMPANY");

    return (
        <div className="w-[292px] h-[52px] bg-[#1F62B9] rounded-[57px] p-[6px] flex">
            <div className={`w-[140px] h-[40px] py-[10px] ${companySelected ? 'bg-[#FFFFFF]' : 'bg-[#1F62B9]'} flex items-center justify-center gap-1 rounded-[46px]`} onClick={() => { onClick("COMPANY")}}>
                {companySelected ? <CompanyBlueIcon/> : <CompanyWhiteIcon/>}
                <span className={`w-[60px] text-[14px] leading-[20.27px] font-extrabold ${companySelected ? 'text-[#1F62B9]' : 'text-[#FFFFFF]'}`}>
                    {t('common.business')}
                </span>
            </div>

            <div className={`w-[140px] h-[40px] py-[10px] ${educationSelected ? 'bg-[#FFFFFF]' : 'bg-[#1F62B9]'} flex items-center justify-center gap-1 rounded-[46px]`} onClick={() => { onClick("EDUCATION") }}>
                {educationSelected ? <EducationBlueIcon/> : <EducationWhiteIcon/>}
                <span className={`w-[47px] text-[14px] leading-[20.27px] font-extrabold ${educationSelected ? 'text-[#1F62B9]' : 'text-[#FFFFFF]'}`}>
                    {t('common.educational')}
                </span>
            </div>
        </div>
    )
}

export default SelectionNavigator;
