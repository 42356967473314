import { useTransition } from "react";
import { CheckIcon, EducationBlueIcon } from "../../icons";
import { useTranslation } from "react-i18next";

const EducationAd = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center w-[948px] min-h-[140px] rounded-[14px] gap-[38px] bg-[#F2F8FF] border-[1px] border-[#1F62B9] border-solid px-[18px] md:px-[20px] lg:w-[90%] md:flex-col md:gap-2 animate-fromTopToBottom">
      <div className="flex flex-col items-center justify-center w-[290px] h-full gap-3">
        <p className="flex flex-row gap-1 items-center">
          <EducationBlueIcon />
          <span className="text-[#1F62B9] font-extrabold font-noto-sans-kr text-[22px]">
            {t("edu.text")}
          </span>
        </p>

        <div className="font-noto-sans-kr text-[15px] tracking-normal font-semibold leading-[22px] text-center w-[240px]">
          {t("edu.textFront")}
          <div className="text-[#FF5038]"> {t("edu.textBack")}</div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-[220px] h-full gap-3 md:mt-[20px]">
        <div className="flex flex-col w-full justify-start gap-1 md:items-center pl-[60px] md:pl-0">
          <p className="font-noto-sans-kr text-[14px] font-semibold tracking-normal leading-[20px]">
            {t("edu.functionTitle")}
          </p>
          <div className="flex items-center gap-1">
            <div>
              <CheckIcon />
            </div>
            <span> {t("edu.report")}</span>
          </div>
          <div className="flex items-center gap-1">
            <div>
              <CheckIcon />
            </div>
            <span>{t("edu.classReport")}</span>
          </div>
          <div className="flex items-center gap-1">
            <div>
              <CheckIcon />
            </div>
            <span className="">{t("edu.consultationReservation")}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1 w-[220px] items-center pl-[50px]  md:mb-[20px] md:pl-0 ">
        <div className="flex items-center gap-1">
          <div>
            <CheckIcon />
          </div>
          <span>{t("edu.notification")}</span>
        </div>
        <div className="flex items-center gap-1">
          <div>
            <CheckIcon />
          </div>
          <span>{t("edu.schoolManagement")}</span>
        </div>
      </div>
    </div>
  );
};

export default EducationAd;
