import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastError, toastSuccess } from "../../utils/toast";
import { AxiosError, AxiosResponse } from "axios";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import i18n from 'i18next';

interface MemberInit {
    token: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    authentication: boolean
}

const initialState: MemberInit = {
    token: '',
    status: 'idle',
    authentication: false
}

export const fetchMemberLogin = createAsyncThunk('member/login',
    async (loginData: LoginDTO) => {
        try{
            const response: AxiosResponse = await axiosInstance.post('/member/login', loginData );
            const token = response.headers['authorization'].split(' ')[1];
            return token;
           
        }catch(error){

            if(error instanceof AxiosError){
                if(error.response?.status === 400){
                    toastError(i18n.t('login.valid.notFound'));
                }else if(error.response?.status === 401){
                    toastError(i18n.t('login.valid.idPwdWrong'));
                }else if(error.response?.status === 403){
                    toastError(i18n.t('login.valid.notAuth'));
                }else {
                    toastError(i18n.t('login.valid.notFound'));
                }
            }
            return '';
        }
    }
);

export const fetchMemberInfo = createAsyncThunk('member/info', 
    async () => {
        try{
            const response = await axiosInstance.post('/member/info',{},requestAuth({}));
            return response.data;
        }catch(error) {
            if(error instanceof AxiosError){
                return error.response?.status;
            }
            return -1;
        }
    }    
)

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMemberLogin.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMemberLogin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                console.log(state);
                if(action.payload){
                    state.token = action.payload;
                    state.authentication = true;

                    sessionStorage.setItem('uc-portal-token', action.payload);
                }else{
                    // toastError(i18n.t('login.valid.idPwdWrong'));
                }
            })
            .addCase(fetchMemberLogin.rejected, (state) => {
                state.status = 'failed';
                state.authentication = false;
            })
            .addCase(fetchMemberInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMemberInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if(action.payload){
                    if(typeof action.payload.data !== 'number' 
                        && action.payload !== 403
                        && action.payload !== 401
                    ){
                        state.token = action.payload;
                        state.authentication = true;
                    }else{
                        state.token = '';
                        state.authentication = false;  
                    }
                }
            })
            .addCase(fetchMemberInfo.rejected, (state) => {
                state.status = 'failed';
                state.authentication = false;
            })
    }
});

export default memberSlice.reducer;
