import { createSlice } from "@reduxjs/toolkit";

interface CardInfoModalState {
  isOpen: boolean;
}

const initialState: CardInfoModalState = {
  isOpen: false,
};

const cardInfoModalSlice = createSlice({
  name: "cardInfoModalSlice",
  initialState,
  reducers: {
    modalOpen(state) {
      state.isOpen = true;
    },
    modalClose(state) {
      state.isOpen = false;
    },
  },
});

export const { modalOpen, modalClose } = cardInfoModalSlice.actions;
export default cardInfoModalSlice.reducer;
