import React from "react";
import { useMediaQuery } from "react-responsive";
import { styled } from "styled-components";
import SliderItem from "./SliderItem";
import { useTranslation } from "react-i18next";

const SliderSection = () => {

    const { t, i18n } = useTranslation();

    const isScreenMD = useMediaQuery({maxWidth: '836px'});
    
    const imgHeightMD = 'h-[30px]';
    const imgHeightLG = 'h-[Hug (43px)]';
    const en: boolean = i18n.language != 'ko' ? true : false;

    const publicLink = `${process.env.PUBLIC_URL}/images/${en ? 'slider_en' : 'slider'}`;

    const imageUrlsOne = [
        `${publicLink}/신세계.png`, 
        `${publicLink}/한국타이어.png`,
        `${publicLink}/gs.png`,
        `${publicLink}/kt.png`,
        `${publicLink}/코레일.png`,
        `${publicLink}/삼성전자.png`,
        `${publicLink}/메르세데스_벤츠.png`
    ];

    const imageUrlsTwo = [
        `${publicLink}/kisti.png`, 
        `${publicLink}/한국국토정보.png`,
        `${publicLink}/동서발전.png`,
        `${publicLink}/한국전략공사.png`,
        `${publicLink}/외교부.png`,
        `${publicLink}/행정안전부.png`,
        `${publicLink}/한국전략공사.png`
    ]

    const imageUrlsThree = [
        `${publicLink}/도이치.png`, 
        `${publicLink}/현대차증권.png`,
        `${publicLink}/산업은행.png`,
        `${publicLink}/부산은행.png`,
        `${publicLink}/교보생명.png`,
        `${publicLink}/월컴.png`,
        `${publicLink}/산업은행.png`, 
    ]

    const imageUrlsFour = [
        `${publicLink}/마리아.png`, 
        `${publicLink}/서울의료원.png`,
        `${publicLink}/대전.png`,
        `${publicLink}/강원도.png`,
        `${publicLink}/제주.png`,
        `${publicLink}/부산광역시.png`,
        `${publicLink}/강원도.png`, 
    ]


    const imgClassName = `inline object-cover ${isScreenMD ? imgHeightMD : imgHeightLG}`;
    return (
        <>
            <div className={`bg-[#F9F9F9] h-[100%] pt-20`}>
                <p className="text-center font-bold font-sans-kr leading-[46.34px] text-[28px] md:text-[20px]" dangerouslySetInnerHTML={{__html : t('main.slideTitle')}}>
                </p>
                <p className="text-center mt-6 font-sans-kr font-semibold text-[#5D5D5D] mb-8 md:text-[13px] md:px-10">
                    {t( "main.slideSubTitle")}
                </p>
            
                <div className={`flex flex-col space-y-6 relative ${isScreenMD ? 'w-[100%]' : 'w-[65%]'} m-[auto]`}>
                    <BlurLeft/>
                        <SliderItem imageUrls={imageUrlsOne} right={true}/>

                        <SliderItem imageUrls={imageUrlsTwo} right={false}/>

                        <SliderItem imageUrls={imageUrlsThree} right={true}/>

                        <SliderItem imageUrls={imageUrlsFour} right={false}/>
                    <BlurRight/>
                </div>
            </div>
        </>
    )
}

const BlurLeft = styled.div`
    background: linear-gradient(90deg, #F9F9F9 14.5%, rgba(249, 249, 249, 0.92) 54.5%, rgba(249, 249, 249, 0) 100%);
    position: absolute;
    height: 100%;
    width: 100px;
    z-index:100;

    top: 20px;
`;

const BlurRight = styled.div`
    background: linear-gradient(90deg, #F9F9F9 14.5%, rgba(249, 249, 249, 0.92) 54.5%, rgba(249, 249, 249, 0) 100%);
    position: absolute;
    transform: rotate(180deg);

    top: -20px;
    right:-1px;
    height: 100%;
    width: 100px;
    z-index:100;
`;

export default SliderSection;

