import React, { useEffect, useState } from "react";
import WatingButton from "./WatingBtn";
import ManageButton from "./ManageBtn";
import { boolean } from "yup";
import axiosInstance from "../../utils/request/axios.instance";
import usePolling from "../../hooks/use.http.polling";
import { useDispatch } from "react-redux";
import { fetchWorkspaceGET } from "../../redux/slice/workspaceSlice";
import { AppDispatch } from "../../redux";
import { handleAdminPageLocate } from "../../utils/common/admin.page";
import { useNavigate } from "react-router-dom";
import { WarningIcon } from "../../icons";
import FailButton from "./FailButton";

interface Props{
    $clicked: boolean;
    delFlag: boolean;
    tenantId: string;
    text:string;
    educationFlag: boolean;
}

const ConversionBtn = ({ $clicked, delFlag, tenantId, text, educationFlag }: Props) => {

    const failCountFun = () => {
        setFailCount((count) => {
            if(count > 14){
                stop();
            }
            return ++count;
        });
    }

    const { start, stop, data } =  usePolling(6000, tenantId, failCountFun);
    const navigate = useNavigate();
    const [failCount, setFailCount] = useState<number>(0);
    const [failBtn, setFailBtn] = useState<boolean>(false);
  
    useEffect(() => {
        if(delFlag){
            start();
        }else{
            stop();
        }

        if(failCount > 3){
            stop();
        }

        return () => {
            stop();
        }
    },[data]);

    return (
       <>
         {failCount > 15  ?  (<FailButton/>) : (
            (delFlag) ? <WatingButton/> : <ManageButton $clicked={$clicked} onClick={() => {
                handleAdminPageLocate(tenantId, () => { navigate('/') });
            }} text={text} tenantId={tenantId} educationFlag={educationFlag}/>
         )}
       </>

      
    )
}

export default ConversionBtn;
