import React, { useTransition } from "react";
import styled from "styled-components";
import { Plus } from "../../icons";

interface Props{
    $clicked: boolean;
    onClick: () => void;
    text: string;
}

const CreateWorkspaceButton = ({ $clicked, onClick, text }: Props) => {
    return (
        <Container $clicked={$clicked} onClick={onClick}>
            <Plus/>
            <Text>
                {text}
            </Text>
        </Container>
    )
}

const Container = styled.button<{ $clicked: boolean }>`
    display: inline-flex;
    height: 42px;
    width: 220px;
    padding: 10px 20px;
    justify-content:center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    background-color:transparent;
    border-radius: 4px;
    border: 1px solid #1F62B9;

    &:hover{
        background-color: ${(props) => props.$clicked ? '#B8D3FE' : '#D9E5F9'};
    }
`;

const Text = styled.span`
    color: #1F62B9;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
`;

export default CreateWorkspaceButton;
