import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { toastError, toastSuccess } from "../utils/toast";
import axiosInstance, { requestAuth } from "../utils/request/axios.instance";


const useModifyUsers = (): [(params: UserModifyParams) => void, boolean] => {
    const navigate = useNavigate();
    const [putLoading, setLoading] = useState<boolean>(false);

    const increaseNumberOfUsers = async (params: UserModifyParams) => {
        setLoading(true);

        try{
            const response = await axiosInstance.put('/workspace/tenant/users/modify', params, requestAuth({}));

            if(response.status === 200){
                toastSuccess('사용자 인원 증가되었습니다.');
            }
            
        }catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                toastError('일시적인 오류입니다.');

            } else {
                toastError('알 수 없는 오류가 발생했습니다.');
            }

        }finally {
            setLoading(false);
            navigate('/team')

        }
    };

    return [increaseNumberOfUsers, putLoading];
}

export default useModifyUsers;