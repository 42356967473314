import { useEffect, useState } from "react";
import Text from "../../ui/Text";

interface Props {
  texts: string[]; // 표시할 텍스트 배열
  typingSpeed?: number; // 타이핑 속도 (밀리초 단위)
  deletingSpeed?: number; // 지우기 속도 (밀리초 단위)
  pauseDuration?: number; // 타이핑 완료 후 멈추는 시간 (밀리초 단위)
}

const TextCursor = ({
  texts,
  typingSpeed = 100,
  deletingSpeed = 50,
  pauseDuration = 1000,
}: Props) => {
  const [displayedText, setDisplayedText] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [subIndex, setSubIndex] = useState<number>(0);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (index >= texts.length) return;

    if (!deleting && subIndex < texts[index].length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + texts[index].charAt(subIndex));
        setSubIndex((prev) => prev + 1);
      }, typingSpeed);

      return () => clearTimeout(timeoutId);
    } else if (deleting && subIndex > 0) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev.slice(0, -1));
        setSubIndex((prev) => prev - 1);
      }, deletingSpeed);

      return () => clearTimeout(timeoutId);
    } else if (!deleting && subIndex === texts[index].length) {
      setTimeout(() => setDeleting(true), pauseDuration);
    } else if (deleting && subIndex === 0) {
      setDeleting(false);
      setIndex((prev) => prev + 1); // 다음 텍스트로 넘어가기
    }
  }, [
    subIndex,
    deleting,
    texts,
    index,
    typingSpeed,
    deletingSpeed,
    pauseDuration,
  ]);
  return (
    <Text className="text-[#FFF] font-bold text-[20px]">{displayedText}</Text>
  );
};

export default TextCursor;
