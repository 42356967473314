import { PropsWithChildren, useEffect, useState } from "react";
import AddHeader from "../../components/Add/AddHeader";
import { useCardRegisterForm } from "../../utils/yupCustom";
import InputCustom from "../../components/Form/Input";
import SelectBoxComponent from "../../components/Common/SelectBox";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { toastError } from "../../utils/toast";
import { useTranslation } from "react-i18next";

interface CardRegisterForm {
    workspaceName: string;
}

const CardRegistration = () => {
    const { t } = useTranslation();
    const workspaceDetail = useSelector((state: RootState) => state.workspaceDetail.workspaceInfo);
    const { register, handleSubmit, formState: { errors } } = useCardRegisterForm<CardRegisterForm>();
    const [region, setRegion] = useState<string>('KR');
    const [emailInputCount, setEmailInputCount] = useState<number[]>([1]);

    const addEmailInput = () => {
        const index = emailInputCount.length + 1;
        
        if(index <= 5){
            setEmailInputCount([...emailInputCount, index]);
        }else{
            toastError('5개 이상 생성불가');
        }
    }

    useEffect(() => {
        toastError(t('not-support'));
        console.log(workspaceDetail);
    }, []);

    return (
        <article className="h-[1200px]">
            <AddHeader backIcon={true}/>
            <section className="flex flex-col items-center gap-4 pb-10">
                <div className="w-[320px] flex flex-col items-center gap-3">
                    <div className="font-noto-sans-kr text-[36px] leading-[52.13px] font-bold mb-5">결제</div>

                    <section className="w-[320px] h-[224px] rounded-[8px] border-[1px] border-[#C8CACE] p-[12px] flex flex-col justify-center gap-1 pl-7 pt-6">
                        <CardRegistrationRow frontText="플랜" backText={workspaceDetail?.licenseType!}/>
                        <CardRegistrationRow frontText="계약 유형" backText="월간 정기 결제"/>
                        <CardRegistrationRow frontText="계약 기간" backText={`${useFormDate(new Date())} ~ ${useOneMonthLater(new Date())}`}/>
                        <CardRegistrationRow frontText="정기 결제일" backText={"매월 "+ new Date().getDate() + "일 후불청구"}/>
                        <CardRegistrationRow frontText="플랜 단가" backText="₩5000/1인"/>
                    </section>

                    <div className="text-[14px] leading-[20.27px] font-normal font-noto-sans-kr">첫 결제월은 신청일부터 말일까지 일할 계산하여 청구됩니다.</div>

                    <Wrapper>
                        <div className="w-full mb-2">
                            <div className="font-noto-sans-kr text-[14px] leading-[20.27px] font-semibold">청구정보</div>
                        </div>

                        <form className="space-y-4">
                            <FormInput placeHolder="조직이름" className="w-[320px]"/>
                            <FormInput placeHolder="대표자명" className="w-[320px]"/>
                            {
                                emailInputCount.map((ind) => (
                                    <FormInput placeHolder="청구 이메일" className="w-[320px]"/>
                                ))
                            }

                            {
                                emailInputCount.length < 5 && (
                                    <div className="h-[20px] font-noto-sans-kr leading-[20.27px] text-[14px] cursor-pointer text-[#1F62B9] font-semibold hover:text-[#B8D3FE]"
                                        onClick={addEmailInput}
                                    >
                                        + 청구 이메일 추가
                                    </div>
                                ) 
                            }

                            <FormInput placeHolder="사용자수" className="w-[320px]"/>
                            <SelectBoxComponent setStateProps={setRegion}/>
                            <FormInput placeHolder="BRN(옵션)" className="w-[320px]"/>

                            <div className="flex flex-col gap-3">
                                <div className="font-noto-sans-kr text-[14px] leading-[20.27px] font-semibold">결제수단</div>
                            
                                <FormInput placeHolder="카드번호" className="w-[320px]"/>
                                <div className="flex flex-row justify-between">
                                    <FormInput placeHolder="유효기간 (MM/YY)" className="w-[150px]"/>
                                    <FormInput placeHolder="CVC" className="w-[150px]"/>
                                </div>
                            </div>
                        </form>

                        <div className="flex flex-row gap-1 mt-6">
                            <input type="checkbox" className="h-5"/>
                            <span className="font-noto-sans-kr text-[14px] leading-[20.27px] font-normal">결제조건을 확인하였으며, 정기 결제 진행에 동의합니다.</span>
                        </div>

                        <button className="w-[320px] h-[42px] rounded-[4px] bg-[#1F62B9] text-[#FFF] font-noto-sans-kr text-[16px] font-semibold mt-5">
                            신청
                        </button>
                    </Wrapper>
                </div>
            </section>
        </article>
    )
}

const Wrapper = styled.section`
    width: 100%;
`;

interface RowProps {
    frontText: string;
    backText: string;
}

interface InputProps {
    placeHolder: string;
    className?: string;
}

const FormInput = ({ placeHolder, className }: InputProps) => {
    return (
        <input className={`h-[42px] border-solid border-[1px] border-[#C8CACE] rounded-[4px] pl-[14px] text-[14px] ${className ?? ''}`} placeholder={placeHolder}/>
    )
}

const CardRegistrationRow = ({ frontText, backText }: RowProps) => {
    return (
        <div className="flex flex-row gap-2">
            <span className="w-[88px] h-[37px] text-[14px] font-normal font-noto-sans-kr leading-[20.27px]">
                {frontText}
            </span>
            <span className="w-[178px] h-[37px] text-[14px] font-normal font-noto-sans-kr leading-[20.27px]">
                {backText}
            </span>
        </div>
    )
}

const useFormDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0'); 

    return `${year}-${month}-${day}`;
}

const useOneMonthLater = (date:Date): string => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + 1); 
    return useFormDate(newDate); 
}

export default CardRegistration;
