import { useEffect, useState } from "react";

interface Props {
  text: string;
  className: string;
}

const LoadingDots = ({ text, className }: Props) => {
  const [dots, setDots] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length < 4) {
          return prevDots + ".";
        } else {
          return "";
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={className}>
      {text}
      {dots}
    </div>
  );
};

export default LoadingDots;
