import React, { FormEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputCustom from "./Input";
import FormButton from "../Button/FormButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginForm } from "../../utils/yupCustom";
import axiosInstance, {
  requestAuth,
  requestUnAuth,
} from "../../utils/request/axios.instance";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { toastError, toastSuccess } from "../../utils/toast";
import { AxiosError, AxiosResponse } from "axios";
import {
  fetchMemberInfo,
  fetchMemberLogin,
} from "../../redux/slice/memberSlice";
import Loadable from "../Lodable";
import { useTranslation } from "react-i18next";
import { fetchWorkspaceGET } from "../../redux/slice/workspaceSlice";
import Loader from "../Loader";
import FullScreenLodable from "../Lodable/FullScreenLoadble";

const LoginForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useLoginForm<LoginDTO>();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const navigate = useNavigate();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const onSubmitFun = async (data: LoginDTO) => {
    try {
      setLoading(true);
      const response: AxiosResponse = await axiosInstance.post(
        "/member/login/auth",
        { ...data },
        requestUnAuth({})
      );
      const token = response.headers["authorization"].split(" ")[1];
      sessionStorage.setItem("uc-portal-token", token);
      setLoading(false);
      navigate("/team");

      return token;
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          toastError(t("login.valid.notFound"));
        } else if (error.response?.status === 401) {
          toastError(t("login.valid.idPwdWrong"));
        } else if (error.response?.status === 403) {
          toastError(t("login.valid.notAuth"));
        } else {
          toastError(t("login.valid.notFound"));
        }
      }
      return "";
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchWorkspaceGET()).then((data) => {
      if (data.payload.status !== 202) {
        setLoading(false);
        navigate("/team");
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmitFun)}>
        {loading && <FullScreenLodable visible={loading} />}
        <>
          <Text>{t("login.text")}</Text>
          <InputCustom
            text={t("login.id")}
            name="username"
            register={register}
            errors={errors}
          />
          <InputCustom
            text={t("login.pwd")}
            name="password"
            register={register}
            errors={errors}
          />
          <FormButton text={t("login.text")} disabled={btnDisable} />

          <OutoLoginText>
            {/*<CheckBox type="checkbox" ref={autoLoginBox}/>*/}
            {/*{t("login.autoLogin")}*/}
          </OutoLoginText>

          <LinkWrapper>
            <Link to={"/mail/pwd"}>{t("login.pwdFind")}</Link>
            <Link
              to={"/join"}
              dangerouslySetInnerHTML={{
                __html: t("login.joinText"),
              }}
            ></Link>
          </LinkWrapper>
        </>
      </Container>
    </>
  );
};

const OutoLoginText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  color: #555;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  div > div > input {
    width: 100px;
  }
`;

const LinkWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    text-decoration-line: none;
    color: #1f62b9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const CheckBox = styled.input`
  width: 13px;
  height: 13px;
  flex-shrink: 0;
`;

const Text = styled.div`
  text-align: center;
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  padding: 20px 0px;
  div > input {
    width: 370px;
    height: 42px;
    padding-left: 15px;
  }

  @media screen and (max-width: 350px) {
    .template-input {
      width: 270px;
    }
  }
`;

export default LoginForm;
