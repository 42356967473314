import React, { useEffect, useMemo, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isElementAccessExpression } from "typescript";

interface Props {
  step: number;
  license: "Free" | "Standard" | "Enterprise" | "NONE";
}

const StepNavigator = ({ step, license }: Props) => {
  let steps = license === "Free" ? [1, 2, 3] : [1, 2, 3];

  if (license === "NONE") steps = [];

  const { t } = useTranslation();
  const TitleMap: { [key: number]: string } = useMemo(() => {
    return {
      1: t("create.servicePlan"),
      2: t("create.team"),
      3: t("create.admin"),
      // 4: "결제",
    };
  }, []);

  useEffect(() => {
    const adjustBodyHeight = () => {
      const sliderEl = document.querySelector(".slick-track") as HTMLElement;
      if (step !== 1 && step !== 4) {
        sliderEl.style.height = "100vh";
      } else {
        sliderEl.style.height = "fit-content";
      }
    };
    adjustBodyHeight();
  }, []);

  return (
    <>
      <StepContainer>
        {steps.map((number, index) => (
          <Step key={number}>
            <Circle $active={step >= number}>
              <span style={{ height: "23px" }}>{number}</span>
            </Circle>
            {index < steps.length - 1 && <Line $active={step > number} />}
          </Step>
        ))}
      </StepContainer>
      <Label>{TitleMap[step]}</Label>
    </>
  );
};

const Circle = styled.div<{ $active: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 50px;
  background-color: ${(props) => (props.$active ? "#1F62B9" : "#CECECE")};
  color: ${(props) => (props.$active ? "white" : "black")};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.$active ? "#1F62B9" : "#CECECE")};
  transition: background-color 0.7s, border-color 0.3s;

  color: #fff;

  text-align: center;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Line = styled.div<{ $active: boolean }>`
  height: 2px;
  width: 68px;
  background-color: ${(props) => (props.$active ? "#1F62B9" : "#CECECE")};
  transition: background-color 0.6s;
  margin-top: 50px;
  @media screen and (max-width: 390px) {
    width: 40px;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
`;

const StepContainer = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const Label = styled.div`
  color: #000;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  transition-duration: 1s;

  @media screen and (max-width: 360px) {
    padding-top: 20px;
    font-size: 20px;
  }
`;

export default StepNavigator;
